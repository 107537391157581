
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPropsContext } from 'next';
import Page, { PageProps } from 'components/Page';
import Service from 'lib/cms/service';
import { Page as IPage } from 'interfaces/cms/page';
import { Preview } from 'components/common/Preview';
function PageNotFound({ page, previewMode }: PageProps) {
    return (<>
      <Preview previewMode={Boolean(previewMode)}/>
      <Page {...{ page }}/>
    </>);
}
async function getStaticProps({ locale, preview, }: GetStaticPropsContext) {
    if (!locale)
        return;
    const strapi = new Service();
    const [layout, settings] = await Promise.all([
        strapi.getLayout(locale),
        strapi.getSettings(locale),
    ]);
    const previewMode = Boolean(preview);
    let page: IPage | null;
    if (previewMode) {
        page = await strapi.getHomePagePreviewByLocale(locale);
    }
    else {
        page = await strapi.getPageBySlugAndLocale('page-not-found', locale);
    }
    return {
        props: {
            locale,
            layout,
            notifications: [],
            popup: false,
            settings,
            page,
            isMultipleLang: false,
            previewMode,
        },
        revalidate: process.env.REVALIDATE_MS
            ? parseInt(process.env.REVALIDATE_MS)
            : 60,
    };
}
export default PageNotFound;

    async function __Next_Translate__getStaticProps__194f3ad74f2__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f3ad74f2__ as getStaticProps }
  